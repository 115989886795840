import React, { Component, useEffect } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import WarningIcon from '@material-ui/icons/Warning';
import { useSnackbar } from "notistack";

import * as firebase from "firebase";
import * as firebaseui from "firebaseui";

import "firebase/functions";
import "firebase/firestore";

const functions = firebase.functions();
const db = firebase.firestore();

function Login() {
  useEffect(() => {
    try {
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", {
        signInFlow: 'popup',
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        signInSuccessUrl: "/",
      });
    } catch (e) {
      console.log(e);
    }
  });

  return <div id="firebaseui-auth-container"></div>;
}

function Row(props) {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState();
  const [data, setData] = React.useState(props.data);
  const [disabled, setDisabled] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const handleTextChange = (event) => {
    setText(event.target.value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async () => {
    setDisabled(true);
    const editProfile = functions.httpsCallable('editProfile'); 
    const response = await (await editProfile({
      type: props.id,
      newData: text
    })).data;
    (response.statusCode === 200) ? enqueueSnackbar(t('account.success')) : enqueueSnackbar(t('account.catch'));
    console.log(response);
    if (props.id !== 'displayName') {
      const dbData = await(await db.doc(`${props.user.claims.teacher ? 'teachers' : 'students'}/${props.user.uid}`).get()).data();
      setData(dbData[props.id])
    } else {
      setData(text);
    }
    
    props.updateUser(firebase.auth().currentUser);
    setDisabled(false);
    handleClose();
  };

  return (
    <TableRow key={props.name}>
      <TableCell>{props.name}</TableCell>
      {!data && (
        <TableCell><WarningIcon /></TableCell>
      )}
      <TableCell align="right">{data || "---"}</TableCell>
      <TableCell align="right">
        <IconButton size="small" onClick={handleClickOpen}>
          <KeyboardArrowRightIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          {props.editable && (
            <div>
              <DialogTitle id="form-dialog-title">
                {t('account.change')} {props.name}
              </DialogTitle>
              <DialogContent>
             <DialogContentText>{t('account.chs')}</DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label={`New ${props.name}`}
                  type="email"
                  fullWidth
                  onChange={handleTextChange}
                  disabled={disabled}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} disabled={disabled} color="primary">
                  {t('account.cancel')}
                </Button>
                <Button onClick={handleChange} disabled={disabled} color="primary">
                  {t('account.change')}
                </Button>
              </DialogActions>
            </div>
          )}
          {!props.editable && (
            <div>
              <DialogTitle id="form-dialog-title">
              {t('account.change')} {props.name}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('account.l1')} {props.name.toLowerCase()} {t('account.l2')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('account.close')}
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </TableCell>
    </TableRow>
  );
}

class PreMyAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      city: undefined,
      gender: undefined,
      wechatId: undefined,
      gradelevel: undefined,
    };
  }

  t = this.props.t;

  render() {
    if (this.props.user) {
      return (
        <Container>
          <Typography variant="h5">{this.t('account.yi')}</Typography>
          <TableContainer component={Paper} style={{}}>
            <Table>
              <TableBody>
                <Row
                  name={this.t('account.n')}
                  id="displayName"
                  data={this.props.user.displayName}
                  editable={true}
                  user={this.props.user}
                  updateUser={this.props.updateUser}
                />
                <Row
                  name={this.t('account.e')}
                  id="email"
                  data={this.props.user.email}
                  editable={false}
                  user={this.props.user}
                  updateUser={this.props.updateUser}
                />
                <Row
                  name={this.t('account.w')}
                  id="wechatId"
                  data={this.props.user.info.wechatId}
                  editable={true}
                  user={this.props.user}
                  updateUser={this.props.updateUser}
                />
                <Row name={this.t('account.gl')} id="gradeLevel" data={this.props.user.info.gradeLevel} editable={true} user={this.props.user} updateUser={this.props.updateUser}/>
                <Row name={this.t('account.gen')} id="gender" data={this.props.user.info.gender} editable={true} user={this.props.user} updateUser={this.props.updateUser}/>
                <Row name={this.t('account.city')} id="city" data={this.props.user.info.city} editable={true} user={this.props.user} updateUser={this.props.updateUser}/>
                <Row name={this.t('account.bio')} id="bio" data={this.props.user.info.bio} editable={true} user={this.props.user} updateUser={this.props.updateUser}/>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      );
    } else if (this.props.user === undefined) {
      return <Skeleton animation="wave" />;
    } else {
      return (
        <p>
          {this.t('account.notlogin')}
        </p>
      );
    }
  }
}

const MyAccount = withTranslation('common')(PreMyAccount);

function Account(props) {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/login`}>
        <Login />
      </Route>
      <Route path={`${path}/myaccount`}>
        <MyAccount updateUser={props.updateUser} user={props.user} />
      </Route>
      <Redirect to={`${path}/login`} />
    </Switch>
  );
}

export default Account;
