import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from '@material-ui/core/Divider';

import * as firebase from "firebase";
import "firebase/firestore";

const db = firebase.firestore();

function SkeletonCard() {
  const { t } = useTranslation('common');

  return (
    <Card style={{ maxWidth: "90%", margin: "25px" }}>
      <CardContent>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" variant="body2" />
      </CardContent>
      <CardActions>
        <Skeleton animation="wave">
          <Button>{t('profile.act')}</Button>
        </Skeleton>
      </CardActions>
    </Card>
  );
}

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

function PastScheduleCard(props) {
  const [rubricOpen, setRubricOpen] = React.useState(false);
  const { t } = useTranslation('common');

  const numToText = (num) => {
    switch(num) {
      case 1:
        return "Needs Improvement";
      case 2:
        return "Satisfactory";
      case 3:
        return "Good";
      case 4:
        return "Excellent";
      default:
        return "Not Found"
    }
  }

  if (!props.data.rubricData) {
    props.data.rubricData = {};
  }

  return (
    <div>
      <Card style={{ maxWidth: "90%", margin: "25px" }}>
        <CardHeader
          avatar={
            <Avatar>
              {props.user.displayName
                .split(" ")
                .map((n) => n[0])
                .join("")}
            </Avatar>
          }
          title={
            props.user.claims.teacher
              ? props.data.studentName
              : t('profile.noassign')
          }
        />
        <CardContent>
          <Typography variant="body1">
            {props.data.studentName}
            <br></br>
            {props.data.timeRequested.toDate().toString()}
            <hr></hr>
            {props.data.comments}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => setRubricOpen(true)}
          >
            {t('profile.openr')}
            </Button>
        </CardActions>
      </Card>
      <Dialog
        open={rubricOpen}
        onClose={() => setRubricOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Here's your rubric:"}
        </DialogTitle>
        <DialogContent>
          <Typography>{t('profile.g')}: {numToText(props.data.rubricData['grammar'])}</Typography>
          <Typography>{t('profile.p')}: {numToText(props.data.rubricData['pronunciation'])}</Typography>
          <Typography>{t('profile.v')}: {numToText(props.data.rubricData['vocabulary'])}</Typography>
          <Typography>{t('profile.c')}: {numToText(props.data.rubricData['comprehension'])}</Typography>
          <Typography>{t('profile.f')}: {numToText(props.data.rubricData['fluency'])}</Typography>
          <Divider />
          <Typography>{t('profile.com')}: {props.data.rubricData['comments']}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRubricOpen(false)} color="primary">
          {t('profile.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

class PrePastCallsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pastCallsList: undefined,
    };
  }

  t = this.props.t;

  renderPastCalls() {
    if (this.state.pastCallsList.length === 0) {
    return <p>{this.t('profile.nothing')}</p>;
    }

    return this.state.pastCallsList.map((data, index) => {
      return <PastScheduleCard key={index} data={data} user={this.props.user} />;
    });
  }

  checkDatabase() {
    if (this.props.user.claims.teacher) {
      db.collection(`teachers/${this.props.user.uid}/completed`)
        .orderBy("timeArchived", "asc")
        .get()
        .then((snap) => {
          const newPendingList = [];
          snap.forEach((doc) => {
            newPendingList.push(doc.data());
          });
          this.setState({
            pastCallsList: newPendingList,
          });
        });
    } else {
      db.collection(`students/${this.props.user.uid}/completed`)
        .orderBy("timeArchived", "asc")
        .get()
        .then((snap) => {
          const newPendingList = [];
          snap.forEach((doc) => {
            newPendingList.push(doc.data());
          });
          this.setState({
            pastCallsList: newPendingList,
          });
        });
    }
  }

  componentDidMount() {
    this.checkDatabase();
  }

  render() {
    return (
      <div>
        <Typography variant="h5">
          {this.t('profile.calls')}
          </Typography>
        {this.state.pastCallsList ? (
          this.renderPastCalls()
        ) : (
            <SkeletonCard />
          )}
      </div>
    );
  }
}

const PastCallsContainer = withTranslation('common')(PrePastCallsContainer);

function CareerProfile(props) {
  const { t } = useTranslation('common');

  if (props.user) {
    return <PastCallsContainer user={props.user} />;
  } else if (props.user === undefined) {
    return <SkeletonCard />;
  } else {
  return <p>{t('profile.signed')}</p>;
  }
}

export default CareerProfile;
