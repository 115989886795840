import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

function Home(props) {
  const history = useHistory();
  const [timeGreeting, setTimeGreeting] = React.useState();
  const { t } = useTranslation('common');

  const incompleteProfile = (user) => {
    if (!user.info || (!user.info.wechatId || !user.info.gradeLevel || !user.info.gender || !user.info.city || !user.info.bio)) {
      return true;
    }
    return false;
  }

  React.useEffect(() => {
    const hours = new Date().getHours();

    if (hours < 12) {
      setTimeGreeting(t('home.gm'))
    }
    if (hours >= 12) {
      setTimeGreeting(t('home.ga'))
    }
    if (hours >= 18) {
      setTimeGreeting(t('home.ge'))
    }
  }, [setTimeGreeting])

  if (props.user) {
    return (
      <div>
        <Typography variant="h4">{timeGreeting || "Welcome Back, "} {props.user.displayName}{t('home.welc')}</Typography>
        <div>
          {incompleteProfile(props.user) && (
            <Alert variant="filled" severity="warning" action={
              <Button color="inherit" size="small" onClick={
                () => history.push('account/myaccount')
              }>{t('home.fix')}</Button>
            }>
              {t('home.prof')}
            </Alert>
          )}
        </div>
        <hr></hr>
        <div>
          <Typography variant="h5">
            {t('home.ql')}
          </Typography>
          <List subheader={<li />}>
            {!props.user.claims.teacher && (
              <ListItem button onClick={() => history.push('/schedule/new')}>
                {t('home.sch')}
              </ListItem>
            )}
            <ListItem button onClick={() => history.push('account/myaccount')}>
              {t('home.ch')}
            </ListItem>
          </List>
        </div>
      </div>
    );
  } else {
    if (props.user === undefined) {
      return (
        <div>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </div>
      );
    } else {
      return <Typography variant="h5">{t('home.log')}</Typography>
    }
  }
}

export default Home;
