import React, { useEffect, Suspense } from "react";
import { Route, BrowserRouter as Router, useHistory } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import "./css/Main.css";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import "bootstrap/dist/css/bootstrap.min.css";
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import Avatar from "@material-ui/core/Avatar";

import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EventIcon from "@material-ui/icons/Event";
import SettingsIcon from '@material-ui/icons/Settings';
import LanguageIcon from '@material-ui/icons/Language';
import { SnackbarProvider } from "notistack";

import * as firebase from "firebase";
import "firebase/auth";
import "firebase/analytics";
import "./FirebaseInit";

import Home from "./Home";
import Schedule from "./Schedule";
import Account from "./Account";
import CareerProfile from "./CareerProfile";
import Admin from "./Admin";

const db = firebase.firestore();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.log('Auth state persistence set to LOCAL');
  }).catch((e) => {
    console.log(e);
  })

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

function SwipeableTemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { t } = useTranslation('common');

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          marginLeft: "25px",
          marginTop: "50px",
        }}
      >
        {(props.user && props.user.displayName) ? (
          <Avatar
            style={{
              color: "white",
              backgroundColor: "#FF5722",
            }}
          >
            {props.user.displayName
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </Avatar>
        ) : (
            <Avatar>?</Avatar>
          )}
        {/* <Typography variant="h6">
          {props.user ? props.user.displayName : "Not Logged In"}
        </Typography> */}
      </div>
      <List>
        <LinkContainer to={`/`}>
          <ListItem button key="home">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t('ui.drawer.h')} />
          </ListItem>
        </LinkContainer>
      </List>
      <Divider />
      <List>
        <LinkContainer to={`/schedule`}>
          <ListItem button key="schedule">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={t('ui.drawer.s')} />
          </ListItem>
        </LinkContainer>
      </List>
      <LinkContainer to={`/careerprofile`}>
        <ListItem button key="careerprofile">
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary={t('ui.drawer.c')} />
        </ListItem>
      </LinkContainer>
      <Divider />
      {(props.user && props.user.claims.admin) && (
        <LinkContainer to={`/admin`}>
          <ListItem button key="admin">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t('ui.drawer.a')} />
          </ListItem>
        </LinkContainer>
      )}
    </div>
  );

  const anchor = "left";
  return (
    <div>
      <React.Fragment key={anchor}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(anchor, true)}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

function LanguageSwitcher() {
  const { i18n } = useTranslation("common");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          marginRight: "10px"
        }}
      >
        <LanguageIcon style={{ color: "white" }}/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClose();
          i18n.changeLanguage('en')
        }}>English</MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          i18n.changeLanguage('cn')
        }}>中文</MenuItem>
      </Menu>
    </div>
  )
}

function TopAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('common');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          background: "#303f9f",
          color: "white",
        }}
      >
        <Toolbar>
          <SwipeableTemporaryDrawer user={props.user} />
          <Typography variant="h6" className={classes.title}>
            {props.user
              ? props.user.claims.teacher
                ? `${props.user.claims.admin ? t('ui.head.admin') : t('ui.head.teacher')} ${t('ui.head.default')}`
                : t('ui.head.student')
              : t('ui.head.default')}
          </Typography>
          <LanguageSwitcher />
          {props.user ? (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <LinkContainer to="/careerprofile">
              <MenuItem onClick={handleClose}>{t('ui.menu.p')}</MenuItem>
                </LinkContainer>
                <LinkContainer to="/account/myaccount">
              <MenuItem onClick={handleClose}>{t('ui.menu.a')}</MenuItem>
                </LinkContainer>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        console.log("Successfully Signed Out!");
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                >
                  {t('ui.menu.l')}
                </MenuItem>
              </Menu>
            </div>
          ) : (
              <LinkContainer to="/account/login">
                <Button
                  color="inherit"
                  style={{
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  {t('ui.menu.lin')}
              </Button>
              </LinkContainer>
            )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

function MainRouter() {
  const [user, setUser] = React.useState(undefined);
  const history = useHistory();

  const updateUser = async (user) => {
    const idTokenResult = await user.getIdTokenResult();
    user.claims = idTokenResult.claims;
    if (user.claims.admin) {
      user.claims.teacher = true;
    }
    const info = await (
      await db.doc(`${user.claims.teacher ? 'teachers' : 'students'}/${user.uid}`).get()
    ).data();

    if (info) {
      user.info = info;
      user.info.gender = capitalizeFirstLetter(user.info.gender);
    } else {
      user.info = {};
    }

    setUser(user);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await updateUser(user);
      } else {
        setUser(user);
        history.push("/account/login");
      }
    });
  });


  return (
    <div>
      <TopAppBar user={user} />
      <Container>
        <div
          className="content"
          style={{
            minHeight: "calc(100vh - 70px)",
            marginTop: "25px",
          }}
        >
          <Route exact path="/" render={() => <Home user={user} />} />
          <Route path="/schedule" render={() => <Schedule user={user} />} />
          <Route path="/account" render={() => <Account user={user} updateUser={updateUser} />} />
          <Route
            key={user ? JSON.stringify(user.info.wechatId) + "account" : "account"}
            path="/careerprofile"
            render={() => <CareerProfile user={user} />}
          />
          <Route path="/admin" render={() => <Admin user={user} />} />
        </div>
      </Container>
    </div>
  );
}

function Main() {
  return (
    <Suspense fallback="loading">
      <Router>
        <SnackbarProvider
          maxSnack={1}
          autoHideDuration={6000}
          style={{ marginBottom: "50px" }}
        >
          <MainRouter />
        </SnackbarProvider>
      </Router>
    </Suspense>
  );
}

export default Main;
