import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_cn from "./content/translations/cn/common.json";
import common_en from "./content/translations/en/common.json";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: {
            common: common_en
        },
        cn: {
            common: common_cn
        }
    }
})

ReactDOM.render((
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <Main />
        </I18nextProvider>
    </React.StrictMode>
), document.getElementById("root"));