import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyB1AdescV6j3g8IqhBQL5v6smzXqpkRKtc",
  authDomain: "speechking-f93ff.firebaseapp.com",
  databaseURL: "https://speechking-f93ff.firebaseio.com",
  projectId: "speechking-f93ff",
  storageBucket: "speechking-f93ff.appspot.com",
  messagingSenderId: "780973185489",
  appId: "1:780973185489:web:4e1875fc86ef90901468f0",
  measurementId: "G-SDE7QLLGDP"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

firebase.analytics();
