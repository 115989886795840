import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import React, { Component, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Row from "react-bootstrap/Row";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {
  Switch,
  useRouteMatch,
  Route,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/functions";

const db = firebase.firestore();
const functions = firebase.functions();

function SkeletonCard() {
  const { t } = useTranslation('common');

  return (
    <Card style={{ maxWidth: "90%", margin: "25px" }}>
      <CardContent>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" variant="body2" />
      </CardContent>
      <CardActions>
        <Skeleton animation="wave">
          <Button>{t('schedule.a')}</Button>
        </Skeleton>
      </CardActions>
    </Card>
  );
}

function ScheduleCardMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('common');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem key={"Delete"} onClick={handleClose}>
          {t('schedule.d')}
        </MenuItem>
        <MenuItem key={"Edit"} onClick={handleClose}>
          {t('schedule.e')}
        </MenuItem>
      </Menu>
    </div>
  );
}

function PendingScheduleCard(props) {
  const [acceptButtonActive, setAcceptButtonActive] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const handleAcceptance = () => {
    if (!window.confirm('Are you sure you want to accept this lesson?')) {
      return;
    }
    setAcceptButtonActive(true);
    enqueueSnackbar(t('schedule.accepting'), { variant: "info" });
    const acceptRequest = functions.httpsCallable("acceptRequest");
    acceptRequest({
      docPath: props.docSnap.ref.path,
    })
      .then((e) => {
        if (!(e.data.statusCode === 200)) {
          console.log(e);
          enqueueSnackbar(t('schedule.catch'), {
            variant: "error",
          });
          setAcceptButtonActive(false);
          props.checkDatabase();
        } else {
          enqueueSnackbar(t('schedule.then'), { variant: "info" });
          props.checkDatabase();
        }
      })
      .catch((e) => {
        enqueueSnackbar(t('schedule.catch'), {
          variant: "error",
        });
        props.checkDatabase();
        setAcceptButtonActive(false);
      });
  };

  return (
    <Card style={{ maxWidth: "90%", margin: "25px" }}>
      <CardHeader
        avatar={
          <Avatar>
            {props.data.studentName
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </Avatar>
        }
        action={<ScheduleCardMenu data={props.data} />}
        title={
          props.user.claims.teacher
            ? props.data.studentName + " | " + props.data.studentWechat
            : t('schedule.noassign')
        }
      />
      <CardContent>
        <Typography variant="body1">
          {t('schedule.st')} {props.data.timeRequested.toDate().toString()}
          <hr></hr>
          {props.user.claims.teacher && (
            <Typography variant="body1">
              {props.data.studentInfo && `${props.data.studentInfo.gradeLevel}, ${props.data.studentInfo.gender}, ${props.data.studentInfo.city}`}
            </Typography>
          )}
          {props.user.claims.teacher && (
            <Typography variant="body1">
              Student WeChatID: {props.data.studentInfo.wechatId}
            </Typography>
          )}
          {props.user.claims.teacher && (
            <Typography variant="body1">
              {props.data.studentInfo && t("schedule.stubio") + props.data.studentInfo.bio}
            </Typography>
          )}
          {t('schedule.c')} {props.data.comments}
        </Typography>
      </CardContent>
      <CardActions>
        {props.user.claims.teacher && (
          <Button
            size="small"
            color="primary"
            disabled={acceptButtonActive}
            onClick={handleAcceptance}
          >
            {t('schedule.acreq')}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

class PrePendingCardsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingList: undefined,
    };
  }

  t = this.props.t;

  checkDatabase = () => {
    if (this.props.user.claims.teacher) {
      db.collection("pending")
        .get()
        .then((snap) => {
          const promiseList = [];
          snap.forEach((doc) => {
            promiseList.push(
              db
                .collection(`pending/${doc.id}/pendingCalls`)
                .orderBy("timeRequested", "asc")
                .get()
            );
          });

          Promise.all(promiseList).then((allDocs) => {
            const newPendingList = [];
            allDocs.forEach((docs) => {
              docs.forEach((doc) => {
                newPendingList.push({
                  data: doc.data(),
                  docSnap: doc,
                });
              });
            });
            this.setState({
              pendingList: newPendingList,
            });
          });
        });
    } else {
      db.collection("pending")
        .doc(this.props.user.uid)
        .collection("pendingCalls")
        .orderBy("timeSent", "asc")
        .get()
        .then((snap) => {
          let newPendingList = [];
          snap.forEach((doc) => {
            newPendingList.push({
              data: doc.data(),
              docSnap: doc,
            });
          });
          this.setState({
            pendingList: newPendingList,
          });
        });
    }
  };

  componentDidMount() {
    this.checkDatabase();
  }

  renderPending() {
    if (this.state.pendingList.length === 0) {
      return <p>{this.t('schedule.null')}</p>;
    }
    return this.state.pendingList.map((data, index) => {
      return (
        <PendingScheduleCard
          key={data.docSnap.id}
          data={data.data}
          docSnap={data.docSnap}
          user={this.props.user}
          checkDatabase={this.checkDatabase}
        />
      );
    });
  }

  render() {
    return (
      <div>
        {this.state.pendingList ? this.renderPending() : <SkeletonCard />}
      </div>
    );
  }
}

const PendingCardsContainer = withTranslation('common')(PrePendingCardsContainer);

function UpcomingScheduleCard(props) {
  const [acceptButtonActive, setAcceptButtonActive] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const { t } = useTranslation('common');

  const [grammar, setGrammar] = React.useState(undefined);
  const [pronunciation, setPronunciation] = React.useState(undefined);
  const [vocabulary, setVocabulary] = React.useState(undefined);
  const [comprehension, setComprehension] = React.useState(undefined);
  const [fluency, setFluency] = React.useState(undefined);
  const [comments, setComments] = React.useState(undefined);

  const [timeTaken, setTimeTaken] = React.useState(undefined);

  const { enqueueSnackbar } = useSnackbar();

  // const handleAcceptance = () => {
  //   console.log(grammar);
  //   console.log(pronunciation);
  //   console.log(vocabulary);
  //   console.log(comprehension);
  //   console.log(fluency);
  //   console.log(comments);
  // }

  const handleAcceptance = () => {
    if (!grammar || !pronunciation || !vocabulary || !comprehension || !fluency) {
      enqueueSnackbar(t('schedule.re'), { variant: "error" });
      return;
    }

    if (!timeTaken || timeTaken > 600 || timeTaken < 0 || typeof timeTaken !== "number") {
      enqueueSnackbar("Looks like you might've made an error with the amount of time taken.", { variant: "error" });
      return;
    }
    
    setAcceptButtonActive(true);
    enqueueSnackbar(t('schedule.accepting'), { variant: "info" });
    const finishCall = functions.httpsCallable("finishCall");
    console.log(props.docSnap.ref.path);
    finishCall({
      docPath: props.docSnap.ref.path,
      rubricData: {
        grammar: grammar,
        pronunciation: pronunciation,
        vocabulary: vocabulary,
        comprehension: comprehension,
        fluency: fluency,
        comments: comments
      },
      minutesTaken: timeTaken
    })
      .then((e) => {
        if (!(e.data.statusCode === 200)) {
          enqueueSnackbar(t('schedule.catch'), {
            variant: "error",
          });
          setAcceptButtonActive(false);
          props.checkDatabase();
        } else {
          enqueueSnackbar(t('schedule.fin'), { variant: "info" });
          props.checkDatabase();
        }
      })
      .catch((e) => {
        console.log(e.message);
        enqueueSnackbar(t('schedule.catch'), {
          variant: "error",
        });
        props.checkDatabase();
        setAcceptButtonActive(false);
      });
  };

  return (
    <div>
      <Card style={{ maxWidth: "90%", margin: "25px" }}>
        <CardHeader
          avatar={
            <Avatar>
              {props.data.studentName
                .split(" ")
                .map((n) => n[0])
                .join("")}
            </Avatar>
          }
          action={<ScheduleCardMenu data={props.data} />}
          title={
            props.user.claims.teacher
              ? props.data.studentName
              : props.data.teacherName
          }
        />
        <CardContent>
          <Typography variant="body1">
            {t('schedule.st')} {props.data.timeRequested.toDate().toString()}
            <hr></hr>
            {props.user.claims.teacher && (
              <Typography variant="body1">
                {props.data.studentInfo && `${props.data.studentInfo.gradeLevel}, ${props.data.studentInfo.gender}, ${props.data.studentInfo.city}`}
              </Typography>
            )}
            {props.user.claims.teacher && (
              <Typography variant="body1">
                Student WeChatID: {props.data.studentInfo.wechatId}
              </Typography>
            )}
            {props.user.claims.teacher && (
              <Typography variant="body1">
                {props.data.studentInfo && t('schedule.stubio') + props.data.studentInfo.bio}
              </Typography>
            )}
            {t('schedule.c')} {props.data.comments}
          </Typography>
        </CardContent>
        <CardActions>
          {props.user.claims.teacher && (
            <Button
              size="small"
              color="primary"
              disabled={acceptButtonActive}
              onClick={() => setConfirmationOpen(true)}
            >
              {t('schedule.f')}
            </Button>
          )}
        </CardActions>
      </Card>
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Mark call request as finished and archive it?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('schedule.fdialog1')}
            {t('schedule.fdialog2')} <a href="https://drive.google.com/file/d/1LgiiZCIU7xJtNW8VLG9ueYrCHYIx7jGE/view?usp=sharing" target="_blank">{t("schedule.fdialog3")}</a>
          </DialogContentText>
          <div>
            <Typography>{t('schedule.g')}</Typography>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" onChange={(event) => setGrammar(parseInt(event.target.value))}>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={t('schedule.ni')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={t('schedule.sa')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label={t('schedule.go')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="primary" />}
                  label={t('schedule.ex')}
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography>{t("schedule.p")}</Typography>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" onChange={(event) => setPronunciation(parseInt(event.target.value))}>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={t('schedule.ni')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={t('schedule.sa')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label={t('schedule.go')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="primary" />}
                  label={t('schedule.ex')}
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography>{t('schedule.v')}</Typography>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" onChange={(event) => setVocabulary(parseInt(event.target.value))}>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={t('schedule.ni')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={t('schedule.sa')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label={t('schedule.go')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="primary" />}
                  label={t('schedule.ex')}
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography>{t('schedule.comp')}</Typography>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" onChange={(event) => setComprehension(parseInt(event.target.value))}>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={t('schedule.ni')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={t('schedule.sa')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label={t('schedule.go')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="primary" />}
                  label={t('schedule.ex')}
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography>{t('schedule.fluent')}</Typography>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" onChange={(event) => setFluency(parseInt(event.target.value))}>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={t('schedule.ni')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={t('schedule.sa')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label={t('schedule.go')}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio color="primary" />}
                  label={t('schedule.ex')}
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Comments"
            multiline
            rows={4}
            variant="outlined"
            onChange={(event) => setComments(event.target.value)}
          />
          <TextField
            id="outlined-number"
            label="Time Taken (minutes)"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            onChange={(event) => {
              setTimeTaken(parseInt(event.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationOpen(false)} color="primary">
            {t("schedule.backbut")}
          </Button>
          <Button
            onClick={() => {
              handleAcceptance();
              setConfirmationOpen(false);
            }}
            color="primary"
            autoFocus
          >
            {t('schedule.continuebut')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

class PreUpcomingCardsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingList: undefined,
    };
  }

  t = this.props.t;

  checkDatabase = () => {
    if (this.props.user.claims.teacher) {
      db.collection("teachers")
        .doc(this.props.user.uid)
        .collection("upcoming")
        .orderBy("timeSent", "asc")
        .get()
        .then((snap) => {
          let newPendingList = [];
          snap.forEach((doc) => {
            newPendingList.push({
              data: doc.data(),
              docSnap: doc,
            });
          });
          this.setState({
            pendingList: newPendingList,
          });
        });
    } else {
      db.collection("students")
        .doc(this.props.user.uid)
        .collection("upcoming")
        .orderBy("timeSent", "asc")
        .get()
        .then((snap) => {
          let newPendingList = [];
          snap.forEach((doc) => {
            newPendingList.push({
              data: doc.data(),
              docSnap: doc,
            });
          });
          this.setState({
            pendingList: newPendingList,
          });
        });
    }
  };

  componentDidMount() {
    this.checkDatabase();
  }

  renderPending() {
    if (this.state.pendingList.length === 0) {
      return <p>{this.t('schedule.null')}</p>;
    }
    return this.state.pendingList.map((data, index) => {
      return (
        <UpcomingScheduleCard
          key={data.docSnap.id}
          data={data.data}
          docSnap={data.docSnap}
          user={this.props.user}
          checkDatabase={this.checkDatabase}
        />
      );
    });
  }

  render() {
    return (
      <div>
        {this.state.pendingList ? this.renderPending() : <SkeletonCard />}
      </div>
    );
  }
}

const UpcomingCardsContainer = withTranslation('common')(PreUpcomingCardsContainer);

class PreDisplaySchedule extends Component {

  t = this.props.t;

  render() {
    if (this.props.user) {
      return (
        <div>
          <Typography variant="h4">{this.t('schedule.pr')}</Typography>
          <PendingCardsContainer user={this.props.user} />
          <Typography variant="h4">{this.t('schedule.uc')}</Typography>
          <UpcomingCardsContainer user={this.props.user} />
          {!this.props.user.claims.teacher && (
            <LinkContainer
              to="./schedule/new"
              style={{
                margin: 0,
                top: "auto",
                right: 20,
                bottom: 20,
                left: "auto",
                position: "fixed",
              }}
            >
              <Fab color="secondary">
                <AddIcon />
              </Fab>
            </LinkContainer>
          )}
        </div>
      );
    } else if (this.props.user === undefined) {
      return <SkeletonCard />;
    } else {
      return (
        <Typography variant="h5">
          {this.t('schedule.log')}
        </Typography>
      );
    }
  }
}

const DisplaySchedule = withTranslation('common')(PreDisplaySchedule);

function ScheduleLesson(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [comment, setComment] = React.useState();
  const [sending, setSending] = React.useState(true);
  const [incomplete, setIncomplete] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const history = useHistory();

  const buttonStyle = {
    margin: "15px",
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleCommentChange = (newComment) => {
    setComment(newComment.target.value);
  };

  useEffect(() => {
    const incompleteProfile = (user) => {
      if (!user.info || (!user.info.wechatId || !user.info.gradeLevel || !user.info.gender || !user.info.city || !user.info.bio)) {
        return true;
      }
      return false;
    }
    if (incompleteProfile(props.user)) {
      setSending(true);
      setIncomplete(true);
    } else {
      setSending(false);
    }
  }, [props.user])

  const handleRequest = () => {
    setSending(true);
    enqueueSnackbar(t('schedule.req'), { variant: "info" });

    const scheduleLesson = functions.httpsCallable("scheduleLesson");

    const daysDiff = Math.ceil(
      (selectedDate - new Date()) / (1000 * 60 * 60 * 24)
    );

    scheduleLesson({
      time: selectedDate.toString(),
      comments: comment,
    })
      .then((resp) => {
        if (resp.data.statusCode === 200) {
          enqueueSnackbar(
            `${t('schedule.reqsuccess1')} ${daysDiff} ${t('schedule.reqsuccess2')}`,
            { variant: "success" }
          );
        } else {
          console.log(resp);
          enqueueSnackbar(
            t('schedule.catch'),
            { variant: "error" }
          );
        }
        setTimeout(() => history.push("/schedule"), 1500);
      })
      .catch((e) => {
        console.log(e);
        setSending(false);
      });
  };

  return (
    <Slide direction="up" in={true}>
      <div>
        <Typography variant="h4">{t('schedule.next')}</Typography>
        <Row>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label={t('schedule.timeselect')}
              inputVariant="outlined"
              value={selectedDate}
              onChange={handleDateChange}
              onError={console.log}
              minDate={new Date()}
              disablePast
              style={buttonStyle}
              disabled={sending ? true : false}
            />
          </MuiPickersUtilsProvider>
        </Row>
        <Row>
          <TextField
            id="outlined-multiline-static"
            label={t('schedule.comments')}
            multiline
            rows={4}
            variant="outlined"
            style={buttonStyle}
            onChange={handleCommentChange}
            disabled={sending ? true : false}
          />
        </Row>
        {incomplete && (
          <Alert variant="filled" severity="warning" action={
            <Button color="inherit" size="small" onClick={
              () => history.push('/account/myaccount')
            }>{t('schedule.fix')}</Button>
          }>
            {t('schedule.icp')}
          </Alert>
        )}
        <Row>
          <Button variant="outlined" style={buttonStyle} disabled={sending ? true : false} onClick={() => history.goBack()}>
            {t('schedule.cancel')}
          </Button>
          <Button
            variant="outlined"
            style={buttonStyle}
            onClick={handleRequest}
            disabled={sending ? true : false}
          >
            {t('schedule.request')}
          </Button>
        </Row>
      </div>
    </Slide>
  );
}

function Schedule(props) {
  let { path } = useRouteMatch();
  const { t } = useTranslation('common');

  if (props.user) {
    return (
      <Switch>
        <Route exact path={`${path}`}>
          <DisplaySchedule user={props.user} />
        </Route>
        <Route path={`${path}/new`}>
          <ScheduleLesson user={props.user} />
        </Route>
        <Redirect to={`${path}`} />
      </Switch>
    );
  } else {
    return (
      <Typography variant="h4">{t('schedule.log')}</Typography>
    )
  }
}

export default Schedule;