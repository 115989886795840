import React, { Component } from "react";
import { Switch, useRouteMatch, Route, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import Skeleton from '@material-ui/lab/Skeleton';
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSnackbar } from 'notistack';
import PhoneIcon from '@material-ui/icons/Phone';
import ButtonGroup from "@material-ui/core/ButtonGroup";

import * as firebase from "firebase";
import 'firebase/firestore';

const db = firebase.firestore();

class ManageWebsiteContainer extends Component {
    render() {
        return <Typography variant="h5">There's nothing here yet.</Typography>
    }
}

class MessagesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: null
        }
    }

    updateMessages = async () => {
        const newMessages = [];

        const docs = await (await db.collection('messages').orderBy("timeSent", "desc").get()).docs;

        docs.forEach((doc) => {
            const data = doc.data();
            console.log(data.timeSent.toDate());
            data.timeSent = data.timeSent.toDate().toString();
            newMessages.push(data);
        })

        this.setState({
            messages: newMessages
        })
    }

    componentDidMount() {
        this.updateMessages();
    }

    render() {
        if (this.state.messages === null) {
            return <Skeleton><MaterialTable /></Skeleton>
        }

        return (
            <div>
                <MaterialTable
                    columns={[
                        { title: 'Email', field: 'email' },
                        { title: 'Name', field: 'name' },
                        { title: 'Message', field: 'message' },
                        { title: 'Time Sent', field: 'timeSent' }
                    ]}
                    data={this.state.messages}
                    title="Messages from speechking.org"
                    options={{
                        rowStyle: {
                            whiteSpace: "nowrap"
                        }
                    }}
                />
            </div>
        )
    }
}

class ManageUsersContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: null,
            nextPageToken: null,
            email: null
        }
    }

    printClaims(customClaims) {
        let output = '';
        for (let claims in customClaims) {
            output += claims + " ";
        }
        return output;
    }

    async getAllUsers() {
        const newUsersList = [];
        const getAllUsers = firebase.functions().httpsCallable('getAllUsers');

        const allUsers = await getAllUsers(this.state.nextPageToken ? { nextPageToken: this.state.nextPageToken } : {});
        if (allUsers.data.statusCode !== 200) {
            console.log(allUsers.data);
            alert('There could\'ve been an error, which was logged to console. Or, you didn\'t have permission to view this content.')
        }
        allUsers.data.userRecords.forEach((data, index) => {
            newUsersList.push({
                name: data.displayName,
                email: data.email,
                customClaims: this.printClaims(data.customClaims)
            })
        })
        this.setState({
            users: newUsersList
        })
    }

    componentDidMount() {
        this.getAllUsers();
    }

    render() {
        if (!this.state.users) {
            return <Skeleton><MaterialTable /></Skeleton>
        }

        return (
            <div>
                <MaterialTable
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Email', field: 'email' },
                        { title: 'Claims', field: 'customClaims' }
                    ]}
                    data={this.state.users}
                    title="All Speechking Users (first 1000)"
                    options={{
                        rowStyle: {
                            whiteSpace: "nowrap"
                        }
                    }}
                />
                <Typography variant="h6">Adding a new teacher?</Typography>
                <AddTeacher />
                <Typography variant="h6">Make someone an admin</Typography>
                <AddAdmin />
                <Typography variant="h6">Clear someone's claims</Typography>
                <ClearClaims />
            </div>
        )
    }
}

function AddTeacher() {
    const [email, setEmail] = React.useState(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = () => {
        enqueueSnackbar('Working...', { variant: "info" })
        const addTeacherRole = firebase.functions().httpsCallable('addTeacherRole');

        addTeacherRole({ email: email }).then((data) => {
            if (!(data.statusCode === 200)) {
                enqueueSnackbar('Something went wrong. Check email spelling? Otherwise, open Javascript console, screenshot the logged error and send to support.', { variant: "error" })
            } else {
                enqueueSnackbar(`Successfully made ${email} a teacher!`, { variant: "success" });
            }
        })
    }

    return (
        <div>
            <TextField onChange={handleEmailChange} />
            <Button value={email} variant="contained" color="primary" onClick={handleSubmit}>Submit!</Button>
        </div>
    )
}

function AddAdmin() {
    const [email, setEmail] = React.useState(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = () => {
        enqueueSnackbar('Working...', { variant: "info" })
        const addAdminRole = firebase.functions().httpsCallable('addAdminRole');

        addAdminRole({ email: email }).then((data) => {
            if (!(data.statusCode === 200)) {
                enqueueSnackbar('Something went wrong. Check email spelling? Otherwise, open Javascript console, screenshot the logged error and send to support.', { variant: "error" })
            } else {
                enqueueSnackbar(`Success! ${email} is now an admin.`, { variant: "success" });
            }
        })
    }

    return (
        <div>
            <TextField onChange={handleEmailChange} />
            <Button value={email} variant="contained" color="secondary" onClick={handleSubmit}>Submit!</Button>
        </div>
    )
}

function ClearClaims() {
    const [email, setEmail] = React.useState(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = () => {
        enqueueSnackbar('Working...', { variant: "info" })
        const clearClaims = firebase.functions().httpsCallable('clearClaims');

        clearClaims({ email: email }).then((data) => {
            if (!(data.statusCode === 200)) {
                enqueueSnackbar('Something went wrong. Check email spelling? Otherwise, open Javascript console, screenshot the logged error and send to support.', { variant: "error" })
                console.log(data);
            } else {
                enqueueSnackbar(`Cleared claims for ${email}.`, { variant: "success" });
            }
        })
    }

    return (
        <div>
            <TextField onChange={handleEmailChange} />
            <Button value={email} variant="contained" onClick={handleSubmit}>Submit!</Button>
        </div>
    )
}

class CallsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            upcomingCalls: undefined,
            completedCalls: undefined,
            tableTitle: "Select something to display",
            tableState: undefined
        }
    }

    getUpcomingCalls() {
        db.collection('students')
            .get()
            .then((snap) => {
                const promiseList = [];
                snap.forEach((doc) => {
                    promiseList.push(
                        db
                            .collection(`students/${doc.id}/upcoming`)
                            .orderBy('timeRequested', "asc")
                            .get()
                    );
                });
                Promise.all(promiseList).then((allDocs) => {
                    const newUpcomingCalls = [];
                    allDocs.forEach((docs) => {
                        docs.forEach((doc) => {
                            newUpcomingCalls.push({
                                data: doc.data(),
                                docSnap: doc
                            })
                        })
                    })
                    this.setState({
                        upcomingCalls: newUpcomingCalls,
                        tableState: 0,
                        tableTitle: "Viewing Upcoming Calls"
                    })
                })
            });
    }

    getCompletedCalls() {
        db.collection('students')
            .get()
            .then((snap) => {
                const promiseList = [];
                snap.forEach((doc) => {
                    promiseList.push(
                        db
                            .collection(`students/${doc.id}/completed`)
                            .orderBy('timeRequested', "asc")
                            .get()
                    );
                });
                Promise.all(promiseList).then((allDocs) => {
                    const newCompletedCalls = [];
                    allDocs.forEach((docs) => {
                        docs.forEach((doc) => {
                            newCompletedCalls.push({
                                data: doc.data(),
                                docSnap: doc
                            })
                        })
                    })
                    this.setState({
                        completedCalls: newCompletedCalls,
                        tableState: 1,
                        tableTitle: "Viewing Completed Calls"
                    })
                })
            });
    }

    updateTableState(state) {
        switch (state) {
            case 0:
                if (!this.state.upcomingCalls) this.getUpcomingCalls();
                else this.setState({ tableState: 0, tableTitle: "Viewing Upcoming Calls" });
                break;
            case 1:
                if (!this.state.completedCalls) this.getCompletedCalls();
                this.setState({ tableState: 1, tableTitle: "Viewing Completed Calls" });
                break;
            default:
                this.setState({ tableState: undefined, tableTitle: "Something went wrong." })
        }
    }

    render() {
        let dataArray = [];
        if (this.state.tableState === 0) {
            dataArray = this.state.upcomingCalls;
        } else if (this.state.tableState === 1) {
            dataArray = this.state.completedCalls;
        }
        console.log(this.state.tableState);

        return (
            <div>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button onClick={() => this.updateTableState(0)}>Upcoming</Button>
                    <Button onClick={() => this.updateTableState(1)}>Completed</Button>
                </ButtonGroup>
                <MaterialTable
                    columns={[
                        { title: "Student Name", field: "data.studentName" },
                        { title: "Teacher Name", field: "data.teacherName" },
                        {
                            title: "Call Time", field: "data.timeRequested", render: (data =>
                                data && (
                                    <Typography>
                                        {data.data.timeRequested.toDate().toString()}
                                    </Typography>
                                )
                            )
                        },
                        { title: "Comments", field: "data.comments" }
                    ]}
                    data={dataArray}
                    title={this.state.tableTitle}
                    options={{
                        rowStyle: {
                            whiteSpace: "nowrap"
                        }
                    }}
                />
            </div>
        )
    }
}

function Admin(props) {
    const { path } = useRouteMatch();
    const [value, setValue] = React.useState(0);
    const history = useHistory();

    if (props.user && !props.user.claims.admin) {
        return (
            <Typography>
                Looks like you don't have access to this panel.
            </Typography>
        )
    }

    return (
        <div>
            <Switch>
                <Route exact path={`${path}/`}>
                    <Typography>Hello Speechking Admins!</Typography>
                    <Typography>The menu's at the bottom. Get that piece of information you need! <span role="img" aria-label="celebration emojis">🥂🍻</span></Typography>
                </Route>
                <Route path={`${path}/manageusers`}>
                    <ManageUsersContainer />
                </Route>
                <Route path={`${path}/messages`}>
                    <MessagesContainer />
                </Route>
                <Route path={`${path}/website`}>
                    <ManageWebsiteContainer />
                </Route>
                <Route path={`${path}/calls`}>
                    <CallsContainer />
                </Route>
            </Switch>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    switch (newValue) {
                        case 0:
                            history.push(`${path}/`)
                            break;
                        case 1:
                            history.push(`${path}/manageusers`)
                            break;
                        case 2:
                            history.push(`${path}/messages`)
                            break;
                        case 3:
                            history.push(`${path}/website`)
                            break;
                        case 4:
                            history.push(`${path}/calls`)
                            break;
                        default:
                            console.error("No Route Specified: " + newValue)
                    }
                    setValue(newValue);
                }}
                showLabels
                style={{
                    width: '75%',
                    position: 'fixed',
                    bottom: 10,
                }}
            >
                <BottomNavigationAction label="Admin Home" icon={<HomeIcon />} />
                <BottomNavigationAction label="Manage Users" icon={<RestoreIcon />} />
                <BottomNavigationAction label="View Messages" icon={<FavoriteIcon />} />
                <BottomNavigationAction label="Manage Website" icon={<LocationOnIcon />} />
                <BottomNavigationAction label="Calls" icon={<PhoneIcon />} />
            </BottomNavigation>
        </div>
    )
}

export default Admin;